import { template as template_32085d3e7f684fe1af035164dd7c5469 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_32085d3e7f684fe1af035164dd7c5469(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
