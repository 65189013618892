import { template as template_a5e7a7f3714546b28a20631c812ed1de } from "@ember/template-compiler";
const FKControlMenuContainer = template_a5e7a7f3714546b28a20631c812ed1de(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
